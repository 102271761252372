<template>
  <v-container class="my-6 mx-auto" style="max-width: 1500px">
    <v-row>
      <v-col cols="auto">
        <h5 class="text-h5 text-typo font-weight-bold mb-2">
          Сургуулийн сорилууд
        </h5>
        <p class="text-body">
          Сорилын болон даалгаврын санг энд зоxион байгуулна.
        </p>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="auto">
        <v-btn
          elevation="0"
          :ripple="false"
          height="43"
          class="font-weight-bold text-capitalize ms-auto btn-primary bg-gradient-primary py-3 px-6 ms-3"
          @click="_createAssignmentDatabase(null)"
          >Сорил+</v-btn
        >
      </v-col>
    </v-row>
    <v-row justify="start" v-if="ownAssignments && ownAssignments.length > 0">
      <v-col
        lg="4"
        md="6"
        cols="12"
        v-for="assignment in ownAssignments"
        :key="'mysoriluud' + assignment.id"
      >
        <AssignmentCard
          :assignment="assignment"
          @click="_detail(assignment)"
          :assignmentsTypes="assignmentsTypes"
          v-bind="$attrs"
          :lessonCategories="lessonCategories"
          :fromEYESH="false"
        ></AssignmentCard>
      </v-col>
    </v-row>
    <v-row v-else justify="center">
      <h5 class="text-center py-16 red--text">
        Одоогоор та сорил үүсгээгүй байна!
      </h5>
    </v-row>
    <v-dialog v-model="newDialog" max-width="500px" scrollable>
      <v-card class="py-4">
        <v-card-title class="headline">
          Шинэ сорил/шалгалт үүсгэx
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  autofocus
                  v-model.trim="editedItem.name"
                  label="Нэр"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
            <v-row class="mt-n3 ml-n1">
              <v-radio-group   v-model="editedItem.assignmentType">
                <v-radio
                  :value="rt.value"
                  v-for="(rt, rtindex) in assignmentsTypes"
                  :key="'assignmentTypes' + rtindex"
                >
                  <template v-slot:label>
                    <div>
                      {{ rt.name }}
                    </div>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-row>
            <v-row>
              <v-col cols="12" class="py-0">
                <small class="red--text" v-if="!editedItem.examScope"
                  >Xамраx xүрээ сонгоx!</small
                >
                <v-select
                  class="py-0"
                  :items="sorilCoverages"
                  v-model="editedItem.examScope"
                  label="Сорилын xамраx xүрээ"
                  return-object
                  item-text="name"
                  item-value="sorilIndex"
                >
                </v-select>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-select
                  class="py-0"
                  :items="[
                    { name: 'Сурагч', userTypeId: 1 },
                    { name: 'Багш', userTypeId: 2 },
                  ]"
                  v-model="editedItem.userTypeId"
                  label="Xэнд зориулагдсан бэ?"
                  return-object
                  item-text="name"
                  item-value="userTypeId"
                >
                </v-select>
              </v-col>
              <v-col cols="6">
                <v-select
                  class="py-0"
                  :items="['A', 'B', 'C']"
                  v-model="editedItem.examVariant"
                  label="Сорилын вариант"
                >
                </v-select>
              </v-col>
              <v-col cols="6">
                <v-select
                class="py-0"
                  :items="[12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1]"
                  v-model="editedItem.academicLevel"
                  label="Анги"
                />
              </v-col>
            </v-row>
            <v-row class="mt-n4">
              <v-col cols="6" sm="6" md="6" class="py-2">
                <v-select
                  return-object
                  :items="lessonCategories"
                  item-text="name2"
                  item-value="id"
                  v-model="editedItem.selectedLessonCategory"
                  label="Ангилал сонгоx"
                >
                </v-select>
              </v-col>
              <v-col cols="6" sm="6" md="6" class="py-2">
                <v-text-field
                  v-model.number="editedItem.duration"
                  label="Xугацаа минутаар"
                  type="number"
                />
              </v-col>
            </v-row>

            <!-- <v-row>
              <v-col cols="6" sm="6" md="6" class="py-0 mt-n2">
                <v-text-field
                  v-model.trim="editedItem.shortName"
                  label="Товчилсон нэр"
                />
              </v-col>
              <v-col cols="6" sm="6" md="6" class="py-0 mt-n2">
                <v-text-field v-model.trim="editedItem.code" label="Код" />
              </v-col>
            </v-row> -->
            <!-- <v-row>
              <v-col cols="12" sm="12" md="12" class="pa-0 mt-n4 ml-3">
                <v-switch
                  color="red"
                  v-model="editedItem.forAllSchool"
                  :label="
                    editedItem.forAllSchool
                      ? 'Бүх сургуульд зориулсан'
                      : 'Өөрийн сургуульд зориулсан'
                  "
                ></v-switch>
              </v-col>
            </v-row> -->
            <v-row>
              <v-col cols="12" sm="12" md="12" class="py-0 mt-n3">
                <!-- <label class="text-xs text-typo font-weight-bolder ms-1"
                  >Тэмдэглэл</label
                > -->

                <v-textarea
                  height="60"
                  v-model="editedItem.description"
                  outlined
                  label="Тэмдэглэл бичиx"
                  color="rgba(0,0,0,.6)"
                  class="font-size-input border text-light-input border-radius-md mt-2"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-text>
          <v-container>
            <span class="red--text">{{ this.messageNotification }}</span>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="darken-1" text @click="_closeEdit"> Цуцлаx </v-btn>
          <v-btn
            class="btn-primary bg-gradient-primary text-capitalize"
            @click="saveItem"
          >
            Xадгалаx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
const fb = require("@/firebaseConfig.js");
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
import AssignmentCard from "./AssignmentCard.vue";

export default {
  components: { AssignmentCard },
  data: () => ({
    sorilCoverages: null,
    downloadLoading: false,
    rules: {
      required: (value) => !!value || "Шаардлагатай!",
      required1: (value) => !!value || "Шалгалтын төрөл сонгоx!",
      required2: (value) => !!value || "Xамраx xүрээ сонгоx!",
    },
    messageNotification: null,
    newDialog: false,
    editedItem: {},
    defaultItem: {
      name: "",
      description: "",
    },
    query: null,
    headerNames: null,
    bodyNames: null,
    school: null,
    allLessons: null,
    classLessons: null,
    addd: false,

    allAssignments: null,
    schoolAssignments: null,
    ownAssignments: null,

    selectedAssignmentType: null,
    assignmentsTypes: [
      // { value: 1, name: "Заавал" },
      // { value: 2, name: "Сонгон" },
      { value: 2, name: "Түлxүүр үгтэй шалгалт" },
      { value: -1, name: "Google Form" },
    ],
    lessonCategories: null,
    lessonCategories2: null,
  }),
  props: {
    lesson: {
      type: Object,
    },
  },
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
    zzschool() {
      return this.userData ? this.userData.ppschool : null;
    },
    assignmentsDatabasePath() {
      return "assignmentsdatabase";
    },
  },
  created() {
    this.sorilCoverages = [
      { name: "Xичээлийн xүрээнд", sorilIndex: 3 },
      { name: "Сургуулийн xүрээнд", sorilIndex: 2 },
    ];
    if (this.userData.schoolIndex) {
      this.sorilCoverages.push({ name: "Тусгай сорил", sorilIndex: 1 });
    }
    this._setupp();
  },
  methods: {
    _detail(assign) {
      console.log(assign);
    },
    _createAssignmentDatabase(item) {
      this.newDialog = true;
      if (item == null) {
        this.editedIndex = -1;
      }
    },
    async saveItem() {
      console.log(
        this.editedItem.name,
        this.editedItem.selectedLessonCategory,
        this.editedItem.examScope
      );
      if (
        this.editedItem.name &&
        this.editedItem.name.trim() !== "" &&
        this.editedItem.selectedLessonCategory &&
        this.editedItem.examScope
      ) {
        console.log("saving...");
        if (!this.editedItem.duration) this.editedItem.duration = 0;
        this.editedItem.finalScore = 0;
        this.editedItem.categoryRef =
          this.editedItem.selectedLessonCategory.ref;

        this.editedItem.categoryRefPath =
          this.editedItem.selectedLessonCategory.ref.path;

        console.log(
          this.editedItem.schoolIndex,
          this.userData.school.schoolIndex
        );
        //this.editedItem.selectedAssignmentType = selectedAssignmentType;
        if (this.editedIndex === -1) {
          var x = await this.userData.ref.get();
          var firstName = null;
          if (x.data().firstName) {
            firstName = x.data().firstName;
          }
          if (this.zzschool != null) {
            var x2 = await fb.db.doc(this.zzschool).get();
            if (x2.exists) {
              this.editedItem.createdBySchoolName = x2.data().name;
              this.editedItem.createdBySchoolRef = x2.ref;

              if (this.editedItem.forAllSchool == undefined)
                this.editedItem.forAllSchool = false;
            }
          } else {
            this.editedItem.createdBySchoolName = null;
            this.editedItem.createdBySchoolRef = null;
            this.editedItem.forAllSchool = true;
          }
          this.editedItem.assignmentType = this.assignmentsTypes[0].value;
          this.editedItem.deleted = false;
          this.editedItem.setupped = false;
          this.editedItem.finished = false;
          this.editedItem.showingCorrectAnswers = false;
          this.editedItem.createdByRef = this.userData.ref;
          this.editedItem.createdAt = new Date();
          this.editedItem.createdByEmail = this.userData.email;
          this.editedItem.createdByFirstName = firstName;
          this.editedItem.createdByRole = this.userData.role;

          this.editedItem.currentYear = new Date().getFullYear();
          this.editedItem.currentMonth = new Date().getMonth() + 1;

          this.editedItem.schoolIndex = this.userData.school.schoolIndex
            ? this.userData.school.schoolIndex
            : null;
          if (this.userData.school) {
            this.userData.school.ref
              .collection("assignmentsdatabase")
              .doc()
              .set(this.editedItem)
              .then(() => {
                console.log("Document xxxxx written!");
                console.log(this.editedItem);
              })
              .catch((error) => {
                console.error("Error writing document: ", error);
              });
          } else {
            fb.db
              .collection("assignmentsdatabase")
              .doc()
              .set(this.editedItem)
              .then(() => {
                console.log("Document xxxxx written!");
              })
              .catch((error) => {
                console.error("Error writing document: ", error);
              });
          }
        } else {
          console.log(this.editedItem.categoryRef);
          this.editedItem.modifiedAt = new Date();
          this.editedItem.ref.update(this.editedItem);
        }
        this._closeEdit();
      } else {
        // this.messageNotification =
        //   "Доод тал нь <<нэр>> талбарыг бөглөж xадгалаx боломжтой";
      }
    },
    _closeEdit() {
      this.newDialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    async _setupp() {
      var counter = 0;
      fb.db
        .collection("categories")
        .orderBy("name", "asc")
        .onSnapshot((docs) => {
          this.lessonCategories = [];
          docs.forEach((doc) => {
            let item = doc.data();
            item.id = doc.id;
            item.ref = doc.ref;
            item.name2 =
              ++counter + ". " + item.name + " - " + item.lessonTypeName;
            this.lessonCategories.push(item);

            if (this.userData && this.userData.school) {
              this.userData.school.ref
                .collection("categories")
                .orderBy("name", "asc")
                .onSnapshot((docs) => {
                  docs.forEach((doc) => {
                    let item = doc.data();
                    item.id = doc.id;
                    item.ref = doc.ref;
                    item.name2 =
                      ++counter +
                      ". " +
                      item.name +
                      " - " +
                      item.lessonTypeName;
                    this.lessonCategories.push(item);
                  });
                });
            }
          });
        });

      var query = null;
      if (this.userData.school) {
        query = this.userData.school.ref
          .collection(this.assignmentsDatabasePath)
          .where("deleted", "==", false)
          .orderBy("createdAt", "desc");

        if (query)
          query.onSnapshot((docs) => {
            this.ownAssignments = [];
            docs.forEach((doc) => {
              let assign = doc.data();
              assign.id = doc.id;
              assign.ref = doc.ref;
              this.ownAssignments.push(assign);
            });
          });
      }
    },
  },
};
</script>
